<template>
  <section>
    <div class="wrap">
      <div id="services" class="services">
        <div class="title">
          <h2>Services</h2>
          <h3>事業内容</h3>
        </div>
        <div class="content">
          <div class="service-list">
            <div class="service top">
              <img src="@/assets/service001.webp" alt="販売代理事業" class="service-img">
              <div class="service-content">
                <h4>
                  <img src="@/assets/service001.webp" alt="販売代理事業" class="service-img-sp">
                  販売代理事業
                </h4>
                <p>
                  特定の商品をメーカーの代理となって販売する販売代理店事業を行っています。店舗様向けの商材販売で最大級の取次件数を誇ります。常に世界トップクラスの成約数をいただいている当社の実績があるからこそ、顧客に特別に提供ができるサービスがございます。
                </p>
              </div>
            </div>
            <div class="service">
              <img src="@/assets/service002.webp" alt="パートナー事業" class="service-img">
              <div class="service-content">
                <h4>
                  <img src="@/assets/service002.webp" alt="パートナー事業" class="service-img-sp">
                  パートナー事業
                </h4>
                <p>
                  当社商材の販売代理店様を募集しております。商材の卸しだけでなく、営業手法などもサポートさせていただいております。業界最高峰のインセンティブ制度と、豊富な商材の取り揃え、万全な営業フォローの体制が弊社の強みとして、全国に販売代理店のパートナー様が存在しています。
                </p>
              </div>
            </div>
            <div class="service">
              <img src="@/assets/service003.webp" alt="ソリューション事業" class="service-img">
              <div class="service-content">
                <h4>
                  <img src="@/assets/service003.webp" alt="ソリューション事業" class="service-img-sp">
                  ソリューション事業
                </h4>
                <p>
                  顧客の課題を発見し、課題解決ができるサービスを取り揃えています。電力、ガス、通信回線、携帯、保険、決済端末、OA機器、MEO集客ツール、SEO対策ツールなど、様々な商材を取り揃えています。顧客とのコミニケーションの中で、課題を発見し課題解決できるサービスのみご提案しています。豊富な商材を取り揃えることで、顧客にメリットがあるサービスをご提案できます。
                </p>
              </div>
            </div>
            <div class="service">
              <img src="@/assets/service004.webp" alt="コンテンツ卸・決済プラットフォーム事業" class="service-img">
              <div class="service-content">
                <h4>
                  <img src="@/assets/service004.webp" alt="コンテンツ卸・決済プラットフォーム事業" class="service-img-sp">
                  コンテンツ卸・<br class="sp-only" />決済プラットフォーム事業
                </h4>
                <p>
                  ユーザーが月額や年額などの定額料金を支払い、サービスを継続的に利用するビジネスモデルです。ストック収益を確保できるので、経営の安定化が図れます。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="products" class="products">
        <div class="title-row">
          <h3>取扱商材</h3>
          <p class="title-message">上記のように、電力、ガス、通信回線、携帯、保険、決済端末、OA機器、MEO集客ツール、SEO対策ツールなど様々な商材を取り揃えています。</p>
        </div>
        <div class="products-list">
          <div class="electric">
            <p>エコログ電気</p>
            <p>ユーセン電気</p>
            <p>HTBエナジー</p>
            <p>エビス電気</p>
            <p>キューエネス電気</p>
          </div>
          <div class="fiber-optic">
            <p>SoftBank 光</p>
            <p>AU 光</p>
            <p>BIGLOBE 光</p>
            <p>docomo 光</p>
            <p>BBIQ 光</p>
          </div>
          <div class="dmm-water">
            <p>DMM チャットブースト</p>
            <p>DMM ジオブースト</p>
            <p>プレミアムウォーター</p>
            <p>ハミングウォーター</p>
          </div>
          <div class="other">
            <p>EPARK キャッシュレス決済端末</p>
            <p>ダイキン業務用エアコン</p>
            <p>IT 保険サービス</p>
            <p>BBFRY</p>
          </div>
        </div>
        <p class="description">
          キャンプ用品のスノーピークで有名な株式会社スノーピークウェル様と契約を結び、スノーピーク様の全国の店舗に、GoogleMapからの集客を強化・効率化するMEOツールを提供しています。このように当社は、全国の有名企業・上場企業と提携や取引をしています。
        </p>
      </div>
    </div>

    <div class="side-cover">
      <!-- 白背景 -->
    </div>
  </section>
</template>

<script>
export default {
  name: "ServiceVue",
}
</script>

<style lang="scss" scoped>
  section {
    position: relative;
    height: calc(240px + 240px + 850px + 40px + 415px);
    padding: 120px 0;
    background-color: var(--sub3);
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      height: calc(120px + 120px + 1090px + 40px + 450px);
    }
    @media screen and (max-width: 767px) {
      padding: 50px 0;
      height: auto;
    }
    .wrap {
      position: absolute;
      top: 240px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        top: 120px;
      }
      @media screen and (max-width: 767px) {
        position: static;
        top: 0;
        left: 0;
        transform: translateX(0);
      }
      .services {
        display: flex;
        flex-wrap: wrap;
        height: 850px;
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          height: 1090px;
        }
        @media screen and (max-width: 767px) {
          display: block;
          height: auto;
        }
        .title {
          width: 35%;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          h2 {
            font-size: 70px;
            font-weight: 600;
            line-height: 70px;
            text-align: start;
            color: var(--sub1);
            margin-bottom: 10px;
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              font-size: 55px;
              line-height: 55px;
            }
            @media screen and (max-width: 767px) {
              font-size: 37px;
              line-height: 37px;
              text-align: center;
            }
          }
          h3 {
            font-size: 18px;
            font-weight: 500;
            color: var(--sub1);
            @media screen and (max-width: 767px) {
              text-align: center;
              margin-bottom: 20px;
            }
          }
        }
        .content {
          width: 65%;
          @media screen and (max-width: 767px) {
            width: 100%;
            padding: 0;
          }
          .service-list {
            .service {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 25px 0;
              border-bottom: 2px solid var(--sub1);
              .service-img {
                width: 20%;
                aspect-ratio: 1/1;
                @media screen and (max-width: 767px) {
                  display: none;
                }
              }
              .service-content {
                width: 75%;
                @media screen and (max-width: 767px) {
                  width: 100%;
                }
                h4 {
                  font-size: 24px;
                  font-weight: 500;
                  margin-bottom: 10px;
                  color: var(--sub1);
                  @media screen and (max-width: 767px) {
                    display: flex;
                    align-items: center;
                    font-size: 21px;
                  }
                  .service-img-sp {
                    display: none;
                    @media screen and (max-width: 767px) {
                      display: block;
                      width: 25%;
                      aspect-ratio: 1/1;
                      margin-right: 20px;
                    }
                  }
                }
              }
            }
            .top {
              border-top: 2px solid var(--sub1);
            }
          }
        }
      }
      .products {
        height: 415px;
        padding: 40px;
        margin-top: 40px;
        background-color: var(--gray);
        @media screen and (min-width: 768px) and (max-width: 1100px) {
          height: 450px;
          padding: 40px 20px;
        }
        @media screen and (max-width: 767px) {
          display: block;
          height: auto;
          background-color: var(--white);
          padding: 20px;
        }
        .title-row {
          display: flex;
          justify-content: space-between;
          @media screen and (max-width: 767px) {
            display: block;
          }
          h3 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20%;
            height: 60px;
            font-size: 28px;
            font-weight: 500;
            color: var(--white);
            background-color: var(--sub2);
            @media screen and (max-width: 767px) {
              width: 100%;
              height: 50px;
              font-size: 21px;
              font-weight: 500;
              margin-bottom: 10px;
            }
          }
          .title-message {
            width: 75%;
            display: flex;
            align-items: center;
            font-weight: 500;
            color: var(--sub1);
            @media screen and (max-width: 767px) {
              width: 100%;
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
        .products-list {
          margin: 40px 0;
          display: flex;
          justify-content: space-around;
          @media screen and (max-width: 767px) {
            display: block;
            margin: 20px 0;
          }
          p {
            font-size: 18px;
            @media screen and (min-width: 768px) and (max-width: 1100px) {
              font-size: 15px;
            }
          }
        }
        .description {
          font-size: 16px;
        }
      }
    }
    .side-cover {
      position: absolute;
      top: 120px;
      left: 0;
      width: 1600px;
      height: calc(240px + 850px + 40px + 415px);
      padding: 120px;
      z-index: 1;
      background-color: var(--white);
      @media screen and (min-width: 768px) and (max-width: 1100px) {
        top: 60px;
        height: calc(120px + 1090px + 40px + 450px);
      }
      @media screen and (max-width: 767px) {
        display: none;
      } 
    }
  }
</style>
